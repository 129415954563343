import React from "react";
import { Link } from "react-router-dom";
import { links } from "../utils";
import { Container, Backdrop } from "./styles";
// import { gsap } from "gsap";
import { addToRefs } from "utils";
import { ReactComponent as BiChevronDown } from "assets/chevron-arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";

function Index({ setShowMenu, popVideo }) {
  const mobileLink = React.useRef([]);
  const [showDropdown, setShowDropdown] = React.useState(false);
  mobileLink.current = [];

  React.useEffect(() => {
    // let tl = gsap.timeline();
    // mobileLink.current.forEach((el, index) => {
    //   tl.from(el, {
    //     ease: "none",
    //     x: -150,
    //     duration: 0.1,
    //     scrollTrigger: {
    //       id: `card-${index}`,
    //       trigger: el,
    //       toggleActions: "play none none none"
    //       // markers: true,
    //     }
    //   });
    // });
    AOS.init();
  }, []);

  const handleMenuClick = (link) => {
    if (link.includes("products")) {
      // console.log(link);
      setShowDropdown(!showDropdown);
    } else {
      popVideo(link);
      setShowMenu(false);
    }
  };

  return (
    <>
      <Backdrop onClick={() => setShowMenu(false)} />
      <Container>
        <nav className="mobile-nav">
          <ul>
            {links.map(({ link, name, dropdown_item }, index) => {
              return (
                <li
                  key={name + index}
                  onClick={() => handleMenuClick(link)}
                  ref={(el) => addToRefs(el, mobileLink)}
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  {dropdown_item ? (
                    <span>
                      <span>{name}</span>
                      <span className={`icon-rotate ${showDropdown && "rotate"}`}>
                        <BiChevronDown />
                      </span>
                    </span>
                  ) : (
                    <Link to={link}>{name}</Link>
                  )}
                  <div style={{ position: "relative" }}>
                    {showDropdown && (
                      <ul className="dropdown">
                        {dropdown_item?.map(({ item, url, icon }, index) => {
                          return (
                            <li
                              className="dropdown-item"
                              key={url + item + index}
                              onClick={() => setShowMenu(false)}
                            >
                              <Link to={url}>
                                <div className="menuIcons">
                                  {icon}
                                  <div className="text">{item}</div>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
        <nav className="onboarding">
          <ul>
            {/* <li onClick={() => setShowMenu(false)}>
              <Link className="login" to="/">
                Login
              </Link>
            </li>
            <li className="get-started" onClick={() => setShowMenu(false)}>
              <Link to="/">Get Started</Link>
            </li> */}
            <li className="get-started" onClick={() => setShowMenu(false)}>
              <Link
                to={{ pathname: "https://app.schooliply.com/#/students/login" }}
                target="_blank"
              >
                Sign Up / Log In
              </Link>
            </li>
          </ul>
        </nav>
      </Container>
    </>
  );
}

export default Index;
