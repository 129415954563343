import useWindowSize from "hooks/useWindowSize";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container } from "./styles";
import { usePathname } from "hooks/usePathName";
import { ReactComponent as LogoLight } from "assets/logoLight.svg";
import { ReactComponent as LogoDark } from "assets/logoDark.svg";
import MenuIcon from "./MenuIcon";
import MobileMenu from "./MobileMenu";
import { links } from "./utils";
//import HowItWorksVideo from "component/HowItWorksVideo";
import { BiChevronDown } from "react-icons/bi";

function Index({ scrollPosition }) {
  const { width } = useWindowSize();
  const location = usePathname();
  const [showMenu, setShowMenu] = React.useState(false);
  const [isDarkLogo, setIsDarkLogo] = React.useState();
  const home = location === "/" || location === "/home";
  //const [isOpen, setOpen] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState();
  const dropdownRef = useRef();

  useEffect(() => {
    if (scrollPosition > 80) {
      setIsDarkLogo(true);
    } else {
      setIsDarkLogo(false);
    }

    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowDropdown(!showDropdown);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };

  }, [showDropdown, scrollPosition]);

  const popVideo = (link) => link.includes("how-it-works"); // && setOpen(true);

  const mouseAction = (link, dropdown) => {
    if(dropdown){
      setShowDropdown(!showDropdown);
    }
    else{
      popVideo(link);
      setShowDropdown(false);
    }
    // if (action === "mouse-enter" && dropdown) {
    //   setShowDropdown(true);
    // }
    // if (action === "mouse-leave" && dropdown) {
    //   setShowDropdown(false);
    // }
  };
  

  return width > 1024 ? (
    <Container home={home} changeBG={isDarkLogo}>
      {home && !isDarkLogo ? (
        <Link to="/" className="logo-desktop">
          <LogoLight />
        </Link>
      ) : (
        <Link to="/" className="logo-desktop">
          <LogoDark />
          {" "}
        </Link>
      )}
      <nav className="desktop">
        <ul>
          {links.map(({ link, name, dropdown_item }, index) => {
            return (
              <li
                key={name + index}
                onClick={() => mouseAction(link, dropdown_item)}>
                {dropdown_item ? (
                  <span>
                    {name}
                    <span className={`icon-rotate ${showDropdown && "rotate"}`}>
                      <BiChevronDown />
                    </span>
                  </span>
                ) : (
                  <Link to={link}>{name}</Link>
                )}
                <div style={{ position: "relative" }}>
                  {showDropdown && dropdown_item && (
                    <ul className="dropdown" ref={dropdownRef}>
                      <div className="dropdown-icon-tip" />
                      {dropdown_item?.map(({ item, url,icon,desc }, index) => {
                        return (
                          <li className="dropdown-item" key={url + item + index}>
                            <Link to={url}>
                              <div className="display-flex display-flex-column">
                                <div className="display-flex align-items-center">
                                  {icon}
                                  <div>{item}</div>
                                </div>
                                <div className="desc">{desc}</div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
      <nav className="desktop">
        <ul>
          <li>
            <Link className="login" to={{pathname: "https://app.schooliply.com/#/students/login"}} target="_blank">
              Login
            </Link>
          </li>
          <li>
            <Link className="get-started" to={{pathname: "https://app.schooliply.com/#/students/signup"}} target="_blank">
              Get Started
            </Link>
          </li>
        </ul>
      </nav>
    </Container>
  ) : (
    <>
      {showMenu && <MobileMenu setShowMenu={setShowMenu} popVideo={popVideo} />}
      <Container home={home} changeBG={isDarkLogo}>
        {home && !isDarkLogo ? (
          <Link to="/" className="logo-mobile">
            <LogoLight />
          </Link>
        ) : (
          <Link to="/" className="logo-mobile">
            <LogoDark />
            {" "}
          </Link>
        )}
        <div className="mobile-menu-btn">
          <MenuIcon
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            isHeaderDark={home && !isDarkLogo}
          />
        </div>
      </Container>
    </>
  );
}

export default Index;
