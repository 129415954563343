import React from "react";
import { Container } from "./styles";
import { ReactComponent as FbIcon } from "assets/facebook.svg";
import { ReactComponent as TwitterIcon } from "assets/twitter.svg";
import { ReactComponent as IGIcon } from "assets/instagram.svg";
import { ReactComponent as YoutubeIcon } from "assets/youtube.svg";
import { ReactComponent as TelegramIcon } from "assets/telegram.svg";
import { ReactComponent as TiktokIcon } from "assets/tiktok.svg";
// import { ReactComponent as WhatsappIcon } from "assets/whatsapp.svg";
import { Link } from "react-router-dom";
//import { usePathname } from "hooks/usePathName";
import { ReactComponent as LogoDark } from "assets/logoDark.svg";
import HowItWorksVideo from "component/HowItWorksVideo";


function Index() {
  //const location = usePathname();
  /*const urlArr = [
    "/schools",
    "/recruiters",
    "/faqs",
    "/contact_us",
    "/privacy_policy",
    "/terms_and_conditions",
    "/community_guidelines",
    "/community",
    "/careers",
    "/blog"
  ];*/
  //dynamically render footer according to page
  //const footerTheme = urlArr.find(e => e === location);
  const [isOpen, setOpen] = React.useState(false);
  const row2_col1 = [
    {
      icon: <FbIcon />,
      url: "https://www.facebook.com/SchooliplyHQ/"
    },
    {
      icon: <TwitterIcon />,
      url: "https://twitter.com/SchooliplyHQ"
    },
    {
      icon: <IGIcon />,
      url: "https://www.instagram.com/schooliplyhq/?hl=en"
    },
    {
      icon: <TelegramIcon />,
      url: "https://t.me/+VfRRDf0vMreKR_Mp"
    },
    {
      icon: <YoutubeIcon />,
      url: "https://www.youtube.com/channel/UC0rTmguZ6HliMAbJ1IB26Dw"
    },
    {
      icon: <TiktokIcon />,
      url: " https://www.tiktok.com/@schooliplyhq"
    },
    /*{
      icon: <WhatsappIcon />,
      url: "https://whatsapp.com"
    }*/
  ];

  const row2_col2 = [
    {
      title: "Company",
      value: [
        { key: "Our Story", url: "/about_us" },
        { key: "Blog", url: "/blog" },
        { key: "Careers", url: "/careers" },
        { key: "FAQs", url: "/faqs" },
        { key: "Contact Us", url: "/contact_us" }
      ]
    },
    {
      title: "Products",
      value: [
        { key: "How it Works", url: "/#how-it-works" },
        { key: "Community", url: "/community" },
        { key: "Become a Scout", url: "/scouts" },
        { key: "Find Programs/Schools", url: "https://app.schooliply.com/#/guest/find-programs", external: true },
        //{ key: "Find Programs/Schools", url: "/schools" },
        // { key: "Blog", url: "/blog" }
      ]
    },
    {
      title: "Legal",
      value: [
        { key: "Terms & Conditions", url: "/terms_and_conditions" },
        { key: "Community Guidelines", url: "/community_guidelines" },
        { key: "Privacy Policy", url: "/privacy_policy" }
      ]
    }
  ];
  
  //const popVideo = (link) => link.includes("how-it-works") && setOpen(true);
  return (
    <>
      <HowItWorksVideo isOpen={isOpen} onClose={() => setOpen(false)} />
      <Container background="light">
        <div className="row">
          <div className="col1">
            <div className="col">
              <Link to="/" className="footerLogoLink">
                <LogoDark />
              </Link>
              <div className="icons">
                {row2_col1?.map(({ icon, url }, index) => {
                  return (
                    <a key={url + index} href={url} target="_blank" rel="noopener noreferrer">
                      {icon}
                    </a>
                  );
                })}
              </div>
              <span className="copyright">Schooliply &copy; {new Date().getFullYear()}. All rights reserved. </span>
            </div>
          </div>
          <div className="col2">
            {row2_col2?.map(({ title, value }, index) => {
              return (
                <div className="col" key={value + index}>
                  <span className="head">{title}</span>
                  {value?.map(({ key, url, external }, idx) => {
                    if(external)
                      return (
                        <ul key={key + index + idx}>
                          <li>
                            <Link to={{pathname: url}} target="_blank">{key}</Link>
                          </li>
                        </ul>
                      );
                    return (
                      <ul key={key + index + idx}>
                        <li>
                          <Link to={url}>{key}</Link>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <span className="copyright mobile">Schooliply &copy; {new Date().getFullYear()}. All rights reserved. </span>
        </div>
      </Container>
    </>
  );
}

export default Index;
